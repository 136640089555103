<template>
  <div>
    <van-nav-bar title="注册" left-text="返回首页" left-arrow @click-left="back2Home" />
    <div class="card">
      <!-- <h2 style="margin-left:10px;margin-top:1.5vh">手机号注册</h2> -->
      <p style="margin-left:12px;margin-top:1.5vh;color:grey">
        注册即代表已阅读并同意
        <router-link to="/userservice">
          <span style="color:red">服务协议</span>
        </router-link>
        和
        <router-link to="/privacypollcy">
          <span style="color:red">隐私政策</span>
        </router-link>
      </p>
      <van-row style="margin-top:5px">
        <van-col span="24">

          <van-checkbox v-model="checked" style="margin-left:12px;margin-top:1.5vh">
            我已仔细阅读以上条款
          </van-checkbox>
          <!-- <van-icon slot="left-icon" name="https://b.yzcdn.cn/vant/icon-demo-1126.png" /> -->
          <van-field
            v-model="regForm.phonenumber"
            type="tel"
            label="手机号(+86)"
            clearable
            required
            maxlength="11"
            placeholder="请输入手机号"
          >
          </van-field>

          <van-field
            v-model="phonecode"
            type="tel"
            center
            clearable
            required
            label="短信验证码"
            placeholder="请输入短信验证码"
            maxlength="6"
          >
            <van-button
              v-if="!istimer"
              slot="button"
              size="small"
              color="#f2826a"
              type="primary"
              :disabled="!vfphone"
              @click="getCode()"
            >获取验证码</van-button>
            <van-button
              v-if="istimer"
              slot="button"
              size="small"
              color="#f2826a"
              type="primary"
              :disabled="istimer"
              @click="getCode()"
            >{{ second+"s" }}</van-button>
          </van-field>
          <van-field
            v-model="code"
            type="tel"
            label="邀请码"
            clearable
            maxlength="10"
            placeholder="如果沒有可不填"
          >
<!--            :disabled="inviteStatus"-->
          </van-field>
        </van-col>
      </van-row>

    </div>
    <van-row type="flex" justify="center">
      <van-button color="#f2826a" :disabled="!canStartReg" style="width:50vw" @click="toReg()">立刻注册</van-button>
      <!-- <van-button color="#f2826a" style="margin-right:20px" @click="toReg()">立刻注册</van-button> -->
    </van-row>

  </div>
</template>
<script>
// import Vue from 'vue'
// const Base64 = require('js-base64').Base64

import { Tab, Tabs, Row, Icon, Col, Cell, CellGroup, Checkbox, Field, Toast, NavBar } from 'vant'
import * as sysTools from '../../utils/sysTools'
import { ckSmsCode, getSmsCode, login, usedphonenumber, bindInviteCode } from '@/api/theApi'
export default {
	components: {
		[Tab.name]: Tab,
		[Tabs.name]: Tabs,
		[Row.name]: Row,
		[Icon.name]: Icon,
		[Col.name]: Col,
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Field.name]: Field,
		[Toast.name]: Toast,
		[NavBar.name]: NavBar,
		[Checkbox.name]: Checkbox
	},
	data() {
		return {
			checked: false,
			active: 2,
			loginForm: {
				username: '',
				pwd: ''
			}, regForm: {
				nativeCode: 'imAinvestcnabc',
				phonenumber: '',
				username: '',
				password: '',
				code: '',
				passwordConfirm: '',
				securities: '',
				securitiesUsername: '',
				securitiesPassword: '',
				email: '',
				securitiesCpass: ''
			},
			phonecode: '',
			istimer: false,
			usedPhone: true,
			lefttime: 0,
			second: 60,
			timmer: null,
			code: '',
			inviteStatus: false
		}
	},
	computed: {
		isready() {
			if (this.loginForm.username.length > 2) {
				return true
			} else {
				return false
			}
		},
		isready2() {
			const pattern = /^1[3456789]\d{9}$/
			if (pattern.test(this.phoneForm.phoneNum) && this.phoneForm.code.length === 6) {
				return true
			} else {
				return false
			}
		},
		theUA() {
			return sysTools.getUA()
		},
		isWeiXin() {
			if (this.theUA.indexOf('micromessenger') !== -1) {
				return true
			} else {
				return false
			}
		},
		vfphone() {
			const pattern = /^1[3456789]\d{9}$/
			// const isused =
			if (pattern.test(this.regForm.phonenumber)) {
				return true
			}
			return false
		},
		canStartReg() {
			if (this.vfSmsCode() && this.vfphonenumber() && this.checked) {
				return true
			} else {
				return false
			}
		}

	},
	created() {

	},
	mounted() {
		let vm = this,
			code = vm.$route.query && vm.$route.query.code;
			code = '';
		vm.code = code;
		if (typeof(code) !==  "undefined"){
			vm.inviteStatus = true
		}
	},
	beforeDestroy() {
		this.second = null
		clearInterval(this.timmer)
	},
	methods: {

		back2Home: function() {
			this.$router.push({
				path: '/'
			})
		},
		timeDiDa() {
			this.istimer = true

			this.timmer = setInterval(() => {
				--this.second
				// console.log(this.second)
				if (this.second < 0) {
					clearInterval(this.timmer)
					this.istimer = false
					this.second = 60
				}
			}, 1000)
		},
		vfSmsCode() {
			// 如果 验证码长度不足 6位 ，返回false
			if (this.phonecode.length !== 6) {
				return false
			}

			return true
		},
		vfphonenumber() {
			//  if (this.regForm.phonenumber.length===11) {
			//   return true
			//  }
			const pattern = /^1[3456789]\d{9}$/
			// const isused =
			if (pattern.test(this.regForm.phonenumber)) {
				return true
			}

			return false
		},

		async ckPhone() {

		},
		async getCode() {
			const phone = await this.vfphonenumber()
			const isusedres = await usedphonenumber(this.regForm.phonenumber)
			const isused = isusedres.data

			console.log(isused)
			if (isused === 'true') {
				// console.log(isused)
				Toast.fail('已注册,请直接登陆')
				this.$router.push({
					path: '/login'
				})
			}
			// if (isused || isused === 'true') {
			//   Toast.fail('已注册,请直接登陆')
			// }
			// console.log(phone)
			if (!phone) {
				Toast.fail('请检查手机号是否正确')
			}

			if (phone && isused === 'false') {
				this.timeDiDa()
				Toast.success('验证码已发送')
				getSmsCode(this.regForm.phonenumber)
			}
		},
		toReg() {
      if (typeof(this.code) ===  "undefined") {
         this.code = ""
      }
			ckSmsCode(this.regForm.phonenumber, this.phonecode).then(res => {
				if (res.msg === 'success') {
					// this.curentStep = 2
					const magicKey = res.data.magicKey// 获取magickey
					const url = '/register/' + this.regForm.phonenumber + '?mk=' + sysTools.b64encode(magicKey)// mk 和手机号 装入url
					if (this.code) {
						bindInviteCode({
							username: this.regForm.phonenumber,
							code: this.code
						})
					}
					this.$router.push({
						path: url
					})
				} else {
					Toast.fail('验证码错误')
				}
			})
		},

		thelogin() {
			const username = this.loginForm.username
			const password = this.loginForm.pwd
			// const data = {
			//   username: username,
			//   password: password
			// }
			login(username, password).then(
				res => {
					if (res.login_status) {
						localStorage.setItem('username', this.loginForm.username)
						localStorage.setItem('login_status', res.login_status)
						localStorage.setItem('logintime', new Date())
						console.log('autologin', res.login_status)
						// const b64u = Base64.encode(this.loginForm.username)
						const url = '/user/'
						this.$router.push({
							path: url
						})
					} else {
						Toast.fail('登录失败')
					}
				}
			).catch(() => {
				Toast.fail('登录失败')
			})
		}
	}
}
</script>
<style lang="less" scoped>
.card {
  margin:12px;
  padding: 2px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 8px 12px #ebedf0;
}
</style>
